/* Only globals here! */

*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans,
		Droid Sans, Helvetica Neue, sans-serif;
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

table {
	border-spacing: 0;
}

a {
	color: #04251d;
}

p {
	margin-top: 0;
}
